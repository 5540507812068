import { CreateLocationOptions, Location } from "../../../../types";
import { useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  getFirestore,
  setDoc,
  Timestamp,
} from "firebase/firestore";

const db = getFirestore();
export const createLocation = async (
  location: Omit<Location, "docID">,
  queryClient: ReturnType<typeof useQueryClient>,
) => {
  try {
    const locationsColRef = collection(db, "locations");
    const docRef = doc(locationsColRef);
    await setDoc(docRef, {
      ...location,
      docRef: docRef,
      dateAdded: Timestamp.now(),
      scannedQRs: 0,
    });
    if (process.env.NODE_ENV === "development")
      console.log("location created with id: ", docRef.id);
    await queryClient.invalidateQueries({ queryKey: ["locations"] });
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error creating location: ", error);
    throw error;
  }
};

export const onCreate = async (
  data: Location,
  selectedOptions: CreateLocationOptions,
  queryClient: ReturnType<typeof useQueryClient>,
) => {
  const { mainTag, additionalTag } = selectedOptions;
  const tags: string[] = [mainTag, additionalTag].filter(Boolean);

  const finalizedData: Omit<Location, "docID"> = {
    ...data,
    tags: tags,
  };

  await createLocation(finalizedData, queryClient);
};
