import { useForm } from "react-hook-form";
import { Location } from "../../../../types";
import UpdateInputs from "../global/UpdateInputs";
import LocationSelector from "../Dropdown/LocationSelector";

export default function UpdateLocation() {
  const methods = useForm<Location>();
  const handleSubmit = async (data: Location) => {
    await console.log(data); //placeholder
  };
  return (
    <>
      <h2 className="text-default-white text-xl pt-3">UPDATE LOCATION</h2>
      <div className="mt-7 h-auto">
        <LocationSelector />
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <UpdateInputs formMethods={methods} />
          <input
            type="submit"
            className="w-full mt-4 py-2 mb-10 bg-admin-purple cursor-pointer"
            value="Update Location"
          />
        </form>
      </div>
    </>
  );
}
