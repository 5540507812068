import { useActionsContext } from "../../../../contexts/ActionsManagerProvider";
import { Actions } from "../../../../reducers/StateReducer";
import { stateLabels } from "./utils";
import { Dropdown } from "../../../index";

export default function ActionDropdown() {
  const context = useActionsContext();

  const { dispatch } = context;

  const divStyle = "absolute pl-9 z-10 mt-32";
  const buttonStyle =
    "p-2 rounded-md h-fit bg-admin-purple text-default-white flex items-center justify-around";
  const liStyle =
    "p-2 h-fit bg-admin-purple text-default-white hover:bg-hover-admin-purple text-center cursor-pointer";
  const actions: Actions[] = [
    { type: "CREATE_LOCATION" },
    { type: "UPDATE_LOCATION" },
    { type: "DELETE_LOCATION" },
  ];

  const actionNames = actions.map((action) => stateLabels[action.type]);
  const isDispatched = (newAction: Actions) => {
    dispatch(newAction);
  };

  return (
    <Dropdown
      items={actionNames}
      label={actionNames[0]}
      liStyle={liStyle}
      divStyle={divStyle}
      buttonStyle={buttonStyle}
      dispatch={isDispatched}
      newStateList={actions}
    />
  );
}
