import { createContext, ReactNode, useContext, useReducer } from "react";
import StateReducer, {
  Actions,
  INITIAL_STATE,
  State,
} from "../reducers/StateReducer";

interface ActionsContextInterface {
  state: State;
  dispatch: (action: Actions) => void;
}

interface Props {
  children: ReactNode;
}

export const ActionsContext = createContext<ActionsContextInterface | null>(
  null,
);

export const useActionsContext = () => {
  const context = useContext(ActionsContext);
  if (!context) {
    throw new Error("Component must be used within a ActionsManagerProvider");
  }
  return context;
};

export default function ActionsManagerProvider({ children }: Props) {
  const [state, dispatch] = useReducer(StateReducer, INITIAL_STATE);

  return (
    <ActionsContext.Provider value={{ state, dispatch }}>
      {children}
    </ActionsContext.Provider>
  );
}
