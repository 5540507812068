import { Decal, useGLTF } from "@react-three/drei";
import React, { useState } from "react";
import * as THREE from "three";
import { GLTFResult } from "../../types";

export function Model(props: JSX.IntrinsicElements["group"]) {
  const group = React.useRef<THREE.Group>(null);
  const { nodes, materials } = useGLTF(
    "/assets/iphone-transformed.glb"
  ) as GLTFResult;

  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = "/assets/resturo-demo.webm";
    vid.crossOrigin = "anonymous";
    vid.loop = true;
    vid.muted = true;
    vid.play();
    return vid;
  });

  const videoTexture = new THREE.VideoTexture(video);
  videoTexture.minFilter = THREE.LinearFilter;
  videoTexture.magFilter = THREE.LinearFilter;

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="60777961f2cdfc9b4e92">
          <mesh name="Screen" geometry={nodes.Screen.geometry}>
            <meshBasicMaterial transparent opacity={0} />
            <Decal scale={[7, 14.2, 1]}>
              <meshBasicMaterial map={videoTexture} />
            </Decal>
          </mesh>
        </group>
        <mesh
          name="141021ca0becd9ed6dbe"
          geometry={nodes["141021ca0becd9ed6dbe"].geometry}
          material={materials.PaletteMaterial001}
        />
        <mesh
          name="2544f5343596116b3326"
          geometry={nodes["2544f5343596116b3326"].geometry}
          material={materials.PaletteMaterial002}
        />
        <mesh
          name="311318fcdb4adc8f5147"
          geometry={nodes["311318fcdb4adc8f5147"].geometry}
          material={materials.PaletteMaterial003}
        />
        <mesh
          name="443220deaff4297ae008"
          geometry={nodes["443220deaff4297ae008"].geometry}
          material={materials.PaletteMaterial004}
        />
        <mesh
          name="85f91bea2bfe5b75ec89"
          geometry={nodes["85f91bea2bfe5b75ec89"].geometry}
          material={materials.PaletteMaterial005}
        />
        <mesh
          name="8c1f8625a38df01a9f38"
          geometry={nodes["8c1f8625a38df01a9f38"].geometry}
          material={materials.PaletteMaterial006}
        />
        <mesh
          name="92b17cae6abca19c4de6"
          geometry={nodes["92b17cae6abca19c4de6"].geometry}
          material={materials.PaletteMaterial007}
        />
        <mesh
          name="29feb9e8dfb497a018e2"
          geometry={nodes["29feb9e8dfb497a018e2"].geometry}
          material={materials.PaletteMaterial008}
        />
        <mesh
          name="c0c967040893828b6cdd"
          geometry={nodes.c0c967040893828b6cdd.geometry}
          material={materials.PaletteMaterial009}
        />
        <mesh
          name="20109f2185a42a027218"
          geometry={nodes["20109f2185a42a027218"].geometry}
          material={materials.PaletteMaterial010}
        />
        <mesh
          name="26f406a9829e95810035"
          geometry={nodes["26f406a9829e95810035"].geometry}
          material={materials.PaletteMaterial011}
        />
        <mesh
          name="ca6475bb0604b76a74bf"
          geometry={nodes.ca6475bb0604b76a74bf.geometry}
          material={materials.PaletteMaterial012}
        />
        <mesh
          name="6e78626027fa9bdf99db"
          geometry={nodes["6e78626027fa9bdf99db"].geometry}
          material={materials.PaletteMaterial014}
        />
        <mesh
          name="fc43fe86eee65edbdbce"
          geometry={nodes.fc43fe86eee65edbdbce.geometry}
          material={materials.PaletteMaterial015}
        />
        <mesh
          name="e3a6c9417b4935be6f1f"
          geometry={nodes.e3a6c9417b4935be6f1f.geometry}
          material={materials.PaletteMaterial016}
        />
      </group>
    </group>
  );
}
