import { Helmet } from "react-helmet-async";
import { SEOutils } from "../../types";

export default function SEO({
  title,
  description,
  keywords,
  faviconUrl,
  imageUrl,
  url,
  jsonLd,
}: SEOutils) {
  return (
    <Helmet>
      <link rel="icon" href={faviconUrl} />

      {/* Google SEO */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(", ")} />
      {jsonLd && (
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      )}

      {/* Open Graph (Facebook) */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Resturio" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="app" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:site" content="@resturio" />
    </Helmet>
  );
}
