import React from "react";
import { useOptionsContext } from "../../../../contexts/OptionsProvider";
import { handleSelection } from "./actions";
import { UseFormSetValue } from "react-hook-form";

interface Props {
  options: string[];
  wrapperClass?: string;
  gridClass?: string;
  buttonClass?: string;
  label: string;
  selectionMode: "single" | "multiple";
  fieldName: string;
  setValue?: UseFormSetValue<any>;
}

export default function OptionsSelector({
  options,
  fieldName,
  setValue,
  wrapperClass,
  gridClass,
  selectionMode,
  label,
  buttonClass,
}: Props) {
  const { selectedOptions, setSelectedOptions } = useOptionsContext();
  const checker = (option: string) => {
    return (
      selectedOptions.mainTag === option ||
      selectedOptions.additionalTag === option
    );
  };

  return (
    <div className={wrapperClass}>
      <p className="text-admin-purple">{label}</p>
      <div className={gridClass}>
        {options.map((option) => (
          <button
            key={option}
            type="button"
            onClick={() =>
              handleSelection({
                option,
                selectionMode,
                fieldName,
                setValue,
                selectedOptions,
                setSelectedOptions,
              })
            }
            className={`${buttonClass} p-2 rounded-md border-b-[1px] text-sm transition-all duration-300 ${
              checker(option)
                ? "text-green-500 border-green-500 shadow-[2px_3px_8px_-5px_#22c55e]"
                : "text-default-white border-transparent"
            }`}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
}
