import React, { SyntheticEvent, useState } from "react";
import { Location } from "../../../../types";
import { useOptionsContext } from "../../../../contexts/OptionsProvider";
import { Path, UseFormRegister } from "react-hook-form";

type FieldName = Path<Location>;

interface Props {
  register: UseFormRegister<Location>;
  fieldName: FieldName;
  label: string;
  For: "main_menu" | "banners" | "reward";
  options?: {
    required?: boolean;
    onChange?: (e: SyntheticEvent) => void;
  };
}

export default function FileInput({
  register,
  fieldName,
  options,
  label,
  For,
}: Props) {
  const { setSelectedOptions } = useOptionsContext();
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  const handleFileChange = async (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    if (target.files) {
      const filesArray = Array.from(target.files);

      const urls = await Promise.all(
        filesArray.map((file) => URL.createObjectURL(file)),
      );
      setPreviewUrls((prevUrls) => [...prevUrls, ...urls]);

      setSelectedOptions((prevOptions) => {
        const auxFiles = { ...prevOptions.files };

        switch (For) {
          case "main_menu":
            auxFiles.main_menu = filesArray[0];
            break;

          case "banners":
            const bannerIndex = auxFiles.banners.length;
            if (!auxFiles.banners[bannerIndex]) {
              auxFiles.banners[bannerIndex] = { images: [] };
            }
            auxFiles.banners[bannerIndex].images = [
              ...(auxFiles.banners[bannerIndex].images || []),
              ...filesArray,
            ];
            break;

          case "reward":
            auxFiles.rewards = [...(auxFiles.rewards || []), filesArray[0]];
            break;

          default:
            break;
        }

        return { ...prevOptions, files: auxFiles };
      });
    }
  };

  const handleDeleteFile = (index: number) => {
    const auxPreviewUrls = [...previewUrls];
    auxPreviewUrls.splice(index, 1);
    setPreviewUrls(auxPreviewUrls);

    setSelectedOptions((prevOptions) => {
      const auxFiles = { ...prevOptions.files };

      switch (For) {
        case "main_menu":
          auxFiles.main_menu = null;
          break;

        case "banners":
          if (auxFiles.banners) auxFiles.banners.splice(index, 1);
          break;

        case "reward":
          if (auxFiles.rewards) auxFiles.rewards.splice(index, 1);
          break;

        default:
          break;
      }

      return { ...prevOptions, files: auxFiles };
    });
  };

  return (
    <div className="mt-4 h-auto">
      <label
        htmlFor={fieldName}
        className="p-2 rounded-md bg-admin-purple text-default-white cursor-pointer block w-fit"
      >
        {label}
      </label>
      <input
        {...register(fieldName, {
          ...options,
          onChange: (e) => handleFileChange(e),
        })}
        id={fieldName}
        type="file"
        multiple={For === "banners"}
        className="hidden"
      />
      <div className="grid grid-cols-2 gap-2 -mt-3">
        {previewUrls.map((url, index) => (
          <div key={index}>
            <button onClick={() => handleDeleteFile(index)}>
              <img
                src="/assets/delete-icon.png"
                alt="Delete Icon"
                className="w-6 h-auto absolute mt-1 ml-1"
              />
            </button>
            <img
              src={url}
              alt="Selected File"
              className="w-30 h-auto object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
