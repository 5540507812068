import { useLocations } from "../../../../contexts/LocationsProvider";
import Dropdown from "../../../global/Dropdown";

export default function LocationSelector() {
  const { locations } = useLocations();
  const locationNames = locations.map((location) => location.name).sort();
  const buttonStyle =
    "py-2 px-5 rounded-md h-fit bg-admin-purple text-default-white flex items-center justify-between w-full";
  const divStyle = "mb-5";
  const liStyle =
    "p-2 h-fit bg-admin-purple text-default-white hover:bg-hover-admin-purple text-center cursor-pointer";
  return (
    <Dropdown
      items={locationNames}
      label={locationNames[0]}
      divStyle={divStyle}
      buttonStyle={buttonStyle}
      liStyle={liStyle}
    />
  );
}
