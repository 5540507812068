import { ReactNode } from "react";

interface CardProps {
  children: ReactNode;
  className?: string;
}
export default function Card({ children, className }: CardProps) {
  return (
    <div className={`bg-dark-gray-70 rounded-xl ${className}`}>{children}</div>
  );
}
