export type State =
  | { type: "CREATE_LOCATION"; componentId: "CREATE" }
  | { type: "UPDATE_LOCATION"; componentId: "UPDATE" }
  | { type: "DELETE_LOCATION"; componentId: "DELETE" };

export type Actions =
  | { type: "CREATE_LOCATION" }
  | { type: "UPDATE_LOCATION" }
  | { type: "DELETE_LOCATION" };

const StateReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "CREATE_LOCATION":
      return { type: action.type, componentId: "CREATE" };
    case "UPDATE_LOCATION":
      return { type: action.type, componentId: "UPDATE" };
    case "DELETE_LOCATION":
      return { type: action.type, componentId: "DELETE" };
    default:
      return state;
  }
};

export const INITIAL_STATE: State = {
  type: "CREATE_LOCATION",
  componentId: "CREATE",
};

export default StateReducer;
