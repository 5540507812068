import { CustomInputProps } from "../../../../types";
import React from "react";

export default function CustomInput({
  placeholder,
  type,
  register,
  fieldName,
  options,
  inputMode,
  onKeyDown,
  className,
}: CustomInputProps) {
  const inputProps: any = {
    ...register(fieldName, options),
    type,
    placeholder,
    className: `${className} w-full bg-transparent placeholder:text-admin-purple text-default-white border-b-[1px] border-admin-purple focus:outline-none invalid:border-red-500 invalid:text-red-500 ${
      type === "number" &&
      "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
    }`,
  };

  if (type === "number") {
    inputProps.inputMode = inputMode;
    inputProps.onKeyDown = onKeyDown;
  }

  return <input {...inputProps} />;
}
