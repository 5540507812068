import { Canvas } from "@react-three/fiber";
import { motion } from "framer-motion";
import { Suspense } from "react";
import { isMobileOnly } from "react-device-detect";
import { HeroText } from "../../types";
import { Model } from "./Iphone";

export default function HeroContents({
  header,
  paragraphs,
  spans,
  listItems,
}: HeroText) {
  const h1Variants = {
    invisible: { opacity: 0, y: 130 },
    visible: { opacity: 1, y: 0 },
  };

  const buttonVariants = {
    invisible: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div className="absolute z-10 w-screen h-auto px-9 ipad:px-20 w-small-laptop:px-44 w-small-laptop:h-3/5 w-small-laptop:flex w-small-laptop:items-center  w-big-laptop:px-56 w-desktop:px-96 w-desktop:h-3/4">
      <div className="w-full h-fit ipad:flex ipad:flex-row ipad:items-center w-desktop:h-full">
        <div className="flex flex-col justify-between ipad:w-1/2">
          <div className="mb-2 overflow-hidden">
            <motion.h1
              className="text-5xl font-bold text-default-white cursor-default ipad:text-5xl h-laptop-sm:md:text-5xl h-laptop-lg:md:text-7xl h-laptop-lg:lg:text-7xl opacity-90"
              initial="invisible"
              animate="visible"
              transition={{
                delay: 0.2,
                staggerChildren: 0.15,
              }}
            >
              {header.split("").map((letter: string, index: number) => {
                return (
                  <motion.span
                    className="inline-block"
                    key={index}
                    variants={h1Variants}
                    transition={{
                      type: "tween",
                      ease: "easeOut",
                      duration: 0.5,
                    }}
                  >
                    {letter}
                  </motion.span>
                );
              })}
            </motion.h1>
          </div>
          <div>
            <p className="text-md text-default-white cursor-default opacity-90 md:text-xl">
              <span className="text-themed-orange">{spans[0]}</span>
              {paragraphs[0]}
            </p>
            <ul className="list-none">
              {listItems.map((item) => (
                <li
                  key={item.id}
                  className="flex items-center space-x-2 text-default-white"
                >
                  <img
                    src={item.icon.src}
                    alt={item.icon.alt}
                    className="w-5 h-5"
                  />
                  <p>{item.text}</p>
                </li>
              ))}
            </ul>
            <p className="text-md text-default-white mt-5 cursor-default opacity-90 md:text-xl">
              <span className="text-themed-orange">{spans[1]}</span>
              {paragraphs[1]}
            </p>
          </div>
          <motion.div
            className="flex flex-col justify-center mt-2 w-big-laptop:w-1/2 w-desktop:w-3/4"
            initial="invisible"
            animate="visible"
            variants={buttonVariants}
            transition={{
              delay: 1,
              duration: 1.2,
            }}
          >
            <button className="flex items-center justify-center mb-3 text-md w-40 text-dark bg-default-white text-center p-2 rounded-xl opacity-80 md:text-lg">
              <p>App Store</p>
              <img
                className="inline-block h-[18px] w-auto -translate-y-[1px] translate-x-1 md:h-[30px] md:-translate-y-[2px]"
                src="/assets/apple2.webp"
                alt="apple logo"
              />
            </button>
            <button className="flex items-center justify-center text-md w-40 text-dark bg-default-white text-center  p-2 rounded-xl opacity-80 md:text-lg">
              Google Play
              <img
                className="inline-block h-[18px] w-auto translate-y-[1px] translate-x-1 md:h-[30px]"
                src="/assets/playstore2.webp"
                alt="playstore logo"
              />
            </button>
          </motion.div>
        </div>
        {!isMobileOnly && (
          <div className="ipad:!h-[320px] ipad:!w-1/2 w-small-laptop:!h-[450px] w-small-laptop:h-phone-landscape:!h-[300px] w-desktop:!h-[600px]">
            <Canvas camera={{ fov: 55, position: [5, -2, 16] }}>
              <ambientLight intensity={5} />
              <Suspense fallback={null}>
                <Model position={[2.3, 0, 0]} />
              </Suspense>
            </Canvas>
          </div>
        )}
      </div>
    </div>
  );
}
