import { useForm } from "react-hook-form";
import { Location, MultiLocation } from "../../../../types";
import UpdateInputs from "../global/UpdateInputs";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useOptionsContext } from "../../../../contexts/OptionsProvider";
import { onCreate } from "./actions";
import { storage } from "../../../../firebase/firebaseConfig";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import CustomInput from "../global/CustomInput";
import { geocode, RequestType, setKey } from "react-geocode";
import { GeoPoint } from "firebase/firestore";

export default function CreateLocation() {
  const methods = useForm<Location>();
  const queryClient = useQueryClient();
  const { selectedOptions, setSelectedOptions } = useOptionsContext();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "");

  const handleSubmit = async (data: Location) => {
    try {
      const { main_menu, banners, rewards } = selectedOptions.files;
      const locationName = data.name.replace(/[^a-zA-Z0-9]/g, "_");

      if (main_menu && banners.length > 0 && rewards.length > 0) {
        const mainMenuRef = ref(
          storage,
          `location_pictures/${locationName}/${main_menu.name}`,
        );
        await uploadBytes(mainMenuRef, main_menu);
        data.picture = await getDownloadURL(mainMenuRef);

        data.multiLocations = await Promise.all(
          banners.map(async (banner, index) => {
            const bannerUrls: string[] = [];

            await Promise.all(
              banner.images.map(async (file) => {
                const bannerRef = ref(
                  storage,
                  `location_pictures/${locationName}/banners/${file.name}`,
                );
                await uploadBytes(bannerRef, file);

                const locationUrl = await getDownloadURL(bannerRef);
                bannerUrls.push(locationUrl);
              }),
            );

            const locationAtIndex: MultiLocation = data.multiLocations[index];
            let geoPointsAtIndex: GeoPoint;

            if (typeof locationAtIndex.geoLocation === "string") {
              geoPointsAtIndex = await geocode(
                RequestType.ADDRESS,
                locationAtIndex.geoLocation,
              )
                .then(({ results }) => {
                  const { lat, lng } = results[0].geometry.location;
                  return new GeoPoint(lat, lng);
                })
                .catch((error) => {
                  console.error(error);
                  return new GeoPoint(0, 0);
                });
            } else {
              geoPointsAtIndex = locationAtIndex.geoLocation;
            }

            return {
              city: locationAtIndex.city,
              geoLocation: geoPointsAtIndex,
              locationName: locationAtIndex.locationName,
              phone: locationAtIndex.phone,
              pictureHash: locationAtIndex.pictureHash,
              pictures: bannerUrls,
            };
          }),
        );

        data.cities = Array.from(
          new Set(
            await Promise.all(
              data.multiLocations.map(async (location) => {
                return location.city;
              }),
            ),
          ),
        );

        data.rewards = await Promise.all(
          rewards.map(async (file, index) => {
            const rewardRef = ref(
              storage,
              `location_pictures/${locationName}/rewards/${file.name}`,
            );
            await uploadBytes(rewardRef, file);
            const rewardUrl = await getDownloadURL(rewardRef);
            const rewardAtIndex = data.rewards[index];
            return {
              rewardCost: rewardAtIndex.rewardCost,
              rewardDescription: rewardAtIndex.rewardDescription,
              rewardPicture: rewardUrl,
              rewardTitle: rewardAtIndex.rewardTitle,
            };
          }),
        );
      }
      await onCreate(data, selectedOptions, queryClient);
      setSuccessMessage("Location created successfully!");
      setErrorMessage(null);
      methods.reset();
      setSelectedOptions({
        mainTag: "",
        additionalTag: "",
        files: {
          main_menu: null,
          banners: [],
          rewards: [],
        },
      });
    } catch (error) {
      setErrorMessage("Failed to create location with error: " + error);
      setSuccessMessage(null);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccessMessage(null);
      setErrorMessage(null);
    }, 5000);

    return () => clearTimeout(timer);
  }, [successMessage, errorMessage]);

  return (
    <>
      <h2 className="text-default-white text-xl pt-3">CREATE LOCATION</h2>
      <div className="mt-7 h-auto pb-7">
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <CustomInput
            register={methods.register}
            fieldName="pictureHash"
            type="text"
            placeholder="Picture Hash Link"
            options={{ required: false }}
            className="mt-4"
          />
          <UpdateInputs formMethods={methods} />
          <input
            type="submit"
            className="w-full mt-4 py-2 bg-admin-purple cursor-pointer"
            value="Create Location"
          />
        </form>

        {successMessage && (
          <p className="text-green-500 mt-2">{successMessage}</p>
        )}
        {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
      </div>
    </>
  );
}
