export default function ContactUs() {
  return (
    <div className="mt-7 ipad:mt-0">
      <h3 className="text-xl text-themed-orange">Contact us:</h3>
      <p className="text-default-white">
        Email:{" "}
        <a
          href="mailto:resturio.suport@gmail.com"
          className="text-themed-orange"
        >
          resturio.suport@gmail.com
        </a>
      </p>
      {/* <p>Phone: </p> */}
    </div>
  );
}
