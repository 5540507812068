import React from "react";
import { isDesktop } from "react-device-detect";
import { aboutUsData } from "../../data";
import { Card } from "../../layout";

export default function AboutUs() {
  return (
    <section
      className={`relative z-10 w-screen flex flex-col items-center justify-center px-9 pb-10 ipad:px-20 w-small-laptop:px-44 w-small-laptop:h-screen w-small-laptop:pb-5 w-big-laptop:px-56 w-desktop:px-96 ${
        !isDesktop ? "bg-dark-orange" : ""
      }`}
      id="about-us"
    >
      {isDesktop && (
        <React.Fragment>
          <div className="overlay absolute z-[4] top-0 left-0 w-screen h-screen"></div>
          <div className="absolute z-[3] top-0 left-0 w-screen h-screen bg-dark-orange-50"></div>
        </React.Fragment>
      )}
      <h2 className="flex items-center z-10 justify-center w-full text-2xl text-themed-orange text-center pb-5 ipad:pb-10 ipad:before:content-[''] ipad:before:flex-1 ipad:before:h-[1px] ipad:before:bg-gradient-to-l ipad:before:from-themed-orange ipad:before:to-transparent ipad:before:mr-2 ipad:after:content-[''] ipad:after:flex-1 ipad:after:h-[1px] ipad:after:bg-gradient-to-r ipad:after:from-themed-orange ipad:after:to-transparent ipad:after:ml-2">
        About us
      </h2>
      <div className="text-default-white z-10 text-l grid grid-cols-1 grid-rows-6 gap-5 ipad:grid-cols-2 ipad:grid-rows-3 w-small-laptop:grid-cols-3 w-small-laptop:grid-rows-2">
        {aboutUsData.map((item) => (
          <Card className="p-5" key={item.id}>
            <h3 className="bg-gradient-to-tr from-default-white via-themed-orange via-40% to-themed-orange bg-clip-text text-transparent font-extrabold text-xl">
              {item.id.toString().padStart(2, "0")}.
            </h3>
            <p className="opacity-80">
              {item.paragraph.includes("<>")
                ? item.paragraph.split("<>").map((text, index) => (
                    <span
                      key={index}
                      className={index % 2 === 0 ? "text-themed-orange" : ""}
                    >
                      {text}
                    </span>
                  ))
                : item.paragraph}
            </p>
          </Card>
        ))}
      </div>
    </section>
  );
}
