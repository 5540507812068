import { ReactNode } from "react";

interface MobileProps {
  children: ReactNode;
  className?: string;
}

export default function MobileLayout({ children, className }: MobileProps) {
  return (
    <section className={`px-9 w-screen h-auto relative ${className}`}>
      {children}
    </section>
  );
}
