import { motion, useMotionTemplate } from "framer-motion";
import { BrowserView } from "react-device-detect";
import { heroContents } from "../../data/index";
import "../../styles/hero.css";
import useMousePosition from "../../utils/useMousePosition";
import HeroContents from "./HeroContents";

export default function Hero() {
  const { x, y } = useMousePosition();
  const maskPos = useMotionTemplate`${x}px ${y}px`;

  return (
    <section className="w-screen min-h-screen flex items-center justify-center ipad:justify-start">
      <HeroContents {...heroContents} />
      <div className="overlay absolute z-[4] top-0 left-0 w-screen h-screen"></div>
      <div className="absolute z-[3] top-0 left-0 w-screen h-screen bg-dark-orange-50"></div>
      <BrowserView>
        <motion.div
          className="mask absolute z-[3] w-screen h-full top-0 left-0"
          style={{
            WebkitMaskPosition: maskPos,
          }}
          transition={{ type: "tween", ease: "easeOut" }}
        ></motion.div>
      </BrowserView>
    </section>
  );
}
