import { useEffect, useRef, useState } from "react";

interface Props {
  onItemClick: (item: string) => void;
  items: string[];
  liStyle: string;
}

export default function DropdownMenu({ onItemClick, items, liStyle }: Props) {
  const listRef = useRef<HTMLUListElement>(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    if (listRef.current) {
      const listHeight = listRef.current.offsetHeight;
      const itemHeight = (listRef.current.children[0] as HTMLElement)
        .offsetHeight;
      const maxHeight = itemHeight * 5;
      setHasScrollbar(listHeight > maxHeight);
    }
  }, [items]);

  const className = hasScrollbar
    ? "max-h-40 overflow-y-auto absolute w-[271px]"
    : "";

  return (
    <ul ref={listRef} className={`${className} mt-2`}>
      {items.map((item: string, index: number) => {
        return (
          <li key={index} className={liStyle} onClick={() => onItemClick(item)}>
            {item}
          </li>
        );
      })}
    </ul>
  );
}
