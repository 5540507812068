import { HashLink } from "react-router-hash-link";
import { navLinksData } from "../../data";

export default function NavLinks() {
  return (
    <nav className="w-2/3 ipad:w-1/2 w-small-laptop:w-1/3">
      <div className="flex justify-around text-default-white text-md">
        {navLinksData.map((data) => {
          return (
            <HashLink
              key={data.id}
              smooth
              to={data.to}
              className={data.className}
            >
              {data.text}
            </HashLink>
          );
        })}
      </div>
    </nav>
  );
}
