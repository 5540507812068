import { UseFormReturn } from "react-hook-form";
import { Location } from "../../../../types";
import CustomInput from "../global/CustomInput";
import OptionsSelector from "./OptionsSelector";
import { additional_tags, main_tags } from "../../../../data";
import FileInput from "./FileInput";
import RewardInputs from "./RewardInputs";
import MultipleLocationInputs from "./MultipleLocationInputs";

interface Props {
  formMethods: UseFormReturn<Location>;
}

export default function UpdateInputs({ formMethods }: Props) {
  const { register } = formMethods;
  return (
    <>
      <CustomInput
        register={register}
        fieldName="name"
        type="text"
        placeholder="Location name"
        options={{ required: false }}
        className="mt-4"
      />
      <CustomInput
        register={register}
        fieldName="description"
        type="text"
        placeholder="Location description"
        options={{ required: false }}
        className="mt-4"
      />
      <CustomInput
        register={register}
        fieldName="pointsRate"
        type="number"
        placeholder="Conversion Rate"
        options={{
          required: false,
          valueAsNumber: true,
        }}
        inputMode="numeric"
        onKeyDown={(e) => {
          if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
            e.preventDefault();
          }
        }}
        className="mt-4 w-full h-auto"
      />
      <MultipleLocationInputs formMethods={formMethods} />
      <FileInput
        register={register}
        fieldName="picture"
        options={{ required: false }}
        label="Choose main image"
        For="main_menu"
      />

      <RewardInputs formMethods={formMethods} />
      <OptionsSelector
        options={main_tags}
        label="Select a cusine tag:"
        fieldName="mainTag"
        selectionMode="single"
        wrapperClass="mt-4"
        gridClass="grid grid-cols-2 gap-2 mt-2"
        buttonClass="bg-admin-dark-3"
      />
      <OptionsSelector
        options={additional_tags}
        label="Select an additional tag:"
        fieldName="additionalTag"
        selectionMode="single"
        wrapperClass="mt-4"
        gridClass="grid grid-cols-2 gap-2 mt-2"
        buttonClass="bg-admin-dark-3"
      />
    </>
  );
}
