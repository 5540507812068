import { createContext, ReactNode, useContext } from "react";
import { useGetLocations } from "../components/Admin/Dashboard/actions";
import { Location } from "../types";

interface LocationsContextInterface {
  locations: Location[];
  isLoading: boolean;
  error: any;
}

type Props = {
  children: ReactNode;
};

const LocationsContext = createContext<LocationsContextInterface | null>(null);

export const useLocations = () => {
  const context = useContext(LocationsContext);
  if (!context) {
    throw new Error("useLocations must be used within a LocationsProvider");
  }
  return context;
};

export default function LocationsProvider({ children }: Props) {
  const { data: locations, isLoading, error } = useGetLocations();

  if (!locations) return <div>No locations found</div>;

  return (
    <LocationsContext.Provider
      value={{ locations: locations ?? null, isLoading, error }}
    >
      {children}
    </LocationsContext.Provider>
  );
}
