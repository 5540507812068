import { ReactNode, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProvider";

type ProtectedRouteProps = {
  children: ReactNode;
  allowedUIDs: string[];
};

export default function ProtectedRoute({
  children,
  allowedUIDs,
}: ProtectedRouteProps) {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();

  if (!currentUser || !allowedUIDs.includes(currentUser.uid)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}
