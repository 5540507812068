import { Helmet } from "react-helmet-async";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function Error() {
  const error = useRouteError();
  let errorMessage: string;

  if (isRouteErrorResponse(error)) {
    errorMessage = error.data.message || error.statusText;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else {
    errorMessage = "Unknown error";
  }

  return (
    <>
      <Helmet>
        <title>Resturio: {errorMessage}</title>
      </Helmet>
      <div className="w-screen h-screen bg-dark flex items-center justify-center">
        <div className="flex flex-col text-center w-4/5">
          <h1 className="text-themed-orange text-5xl pb-5">Oops!</h1>
          <p className="text-default-white text-sm md:text-md lg:text-xl">
            Sorry, an unexpected error has occurred.
          </p>
          <p className="text-themed-orange text-sm md:text-md lg:text-xl">
            Error:
            <i> {errorMessage}</i>
          </p>
          <p className="text-default-white text-sm md:text-md lg:text-xl">
            The page you were looking for might've not been found, but hey, you
            can still download our app here:
          </p>
          <div className="flexCol justify-center w-4/5 mx-auto mt-5 md:flexRow md:w-[50%] lg:w-1/3">
            <button className="flex items-center justify-center mb-3 text-md w-40 text-dark bg-default-white text-center  p-2 rounded-xl md:text-lg md:mb-0">
              <p>App Store </p>
              <img
                className="inline-block h-[20px] w-auto -translate-y-[1px] translate-x-1 md:h-[30px] md:-translate-y-[2px]"
                src="/assets/apple2.webp"
                alt="apple logo"
              />
            </button>
            <button className="flex items-center justify-center text-md w-40 text-dark bg-default-white text-center  p-2 rounded-xl md:text-lg">
              Google Play{" "}
              <img
                className="inline-block h-[20px] w-auto translate-y-[1px] translate-x-1 md:h-[30px]"
                src="/assets/playstore2.webp"
                alt="playstore logo"
              />
            </button>
          </div>
          <a
            href="/"
            className="text-themed-orange pt-5 text-sm md:text-md lg:text-xl"
          >
            Take me back home
          </a>
        </div>
      </div>
    </>
  );
}
