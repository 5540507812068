import { useContext } from "react";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { useGetUsername, useHandleSignOut } from "../actions";

export default function AdminNavbar() {
  const handleSignOut = useHandleSignOut();
  const { currentUser } = useContext(AuthContext);
  const { data: username, isLoading } = useGetUsername();

  return (
    <header className="absolute px-9 top-0 z-20 flex w-full justify-center bg-admin-dark">
      <div className="flex flex-row items-center justify-between w-full text-default-white text-md py-5">
        {!currentUser || isLoading ? (
          <div>Loading...</div>
        ) : (
          username && (
            <h1>
              Logged in as <span className="text-admin-purple">{username}</span>
            </h1>
          )
        )}
        <button
          onClick={() => handleSignOut()}
          className="px-2 rounded-md h-fit bg-admin-purple shadow-[0px_0px_17px_-4px_#6c5ce7]"
        >
          <p className="py-2 px-4">Logout</p>
        </button>
      </div>
    </header>
  );
}
