import { useActionsContext } from "../../../contexts/ActionsManagerProvider";
import CreateLocation from "./StateLocations/CreateLocation";
import UpdateLocation from "./StateLocations/UpdateLocation";
import { OptionsProvider } from "../../../contexts/OptionsProvider";

const componentMap = {
  CREATE: (
    <OptionsProvider>
      <CreateLocation />
    </OptionsProvider>
  ),
  UPDATE: <UpdateLocation />,
  DELETE: <div>Delete Location Component Coming Soon...</div>,
};

export default function LocationManager() {
  const context = useActionsContext();

  const { state } = context;
  return (
    <div className="w-auto h-auto bg-admin-dark rounded-md shadow-[1px_3px_8px_-5px_#6c5ce7] px-4">
      {componentMap[state.componentId]}
    </div>
  );
}
