import { UseFormSetValue } from "react-hook-form";
import { CreateLocationOptions } from "../../../../types";
import { Dispatch, SetStateAction } from "react";

type SelectionProps = {
  option: string;
  selectionMode: "single" | "multiple";
  fieldName: string;
  setValue?: UseFormSetValue<any>;
  selectedOptions: CreateLocationOptions;
  setSelectedOptions: Dispatch<SetStateAction<CreateLocationOptions>>;
};

export const handleSelection = ({
  option,
  selectionMode,
  fieldName,
  setValue,
  selectedOptions,
  setSelectedOptions,
}: SelectionProps) => {
  if (selectionMode === "single") {
    switch (fieldName) {
      case "mainTag": {
        const mainTag = selectedOptions.mainTag === option ? "" : option;
        setSelectedOptions((prev) => ({
          ...prev,
          mainTag: mainTag,
        }));
        break;
      }
      case "additionalTag": {
        const newAdditionalTag =
          selectedOptions.additionalTag === option ? "" : option;
        setSelectedOptions((prev) => ({
          ...prev,
          additionalTag: newAdditionalTag,
        }));
        break;
      }
      default:
        break;
    }
  } else if (selectionMode === "multiple") {
    // switch (fieldName) {
    //   case "cities": {
    //     const isCitySelected = selectedOptions.cities.includes(option);
    //     const citySelection = isCitySelected
    //       ? selectedOptions.cities.filter((city) => city !== option)
    //       : [...selectedOptions.cities, option];
    //
    //     setSelectedOptions((prev) => ({
    //       ...prev,
    //       cities: citySelection,
    //     }));
    //     setValue("cities", citySelection);
    //     break;
    //   }
    // } No multiple selections yet, keep as an example
  }
};
