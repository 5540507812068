import React from "react";
import { Path, useFieldArray, UseFormReturn } from "react-hook-form";
import { Location } from "../../../../types";
import FileInput from "../global/FileInput";

interface Props {
  formMethods: UseFormReturn<Location>;
}

type FormPath = Path<Location>;

export default function RewardInputs({ formMethods }: Props) {
  const { register, control } = formMethods;
  const { fields, append, remove } = useFieldArray<Location>({
    control,
    name: "rewards",
  });

  return (
    <div className="mt-8">
      {fields.map((field, index) => (
        <div
          key={field.id}
          className={`bg-admin-dark-3 rounded-md flex items-center justify-center ${
            index > 0 ? "mt-2" : "mt-4"
          }`}
        >
          <div className="w-11/12">
            <h3 className="pt-2 text-default-white">Reward {index + 1}</h3>
            <input
              {...register(`rewards.${index}.rewardTitle` as FormPath)}
              type="text"
              placeholder="Reward name"
              className="mt-4 w-full bg-transparent placeholder:text-admin-purple text-default-white border-b-[1px] border-admin-purple focus:outline-none invalid:border-red-500 invalid:text-red-500"
            />
            <input
              {...register(`rewards.${index}.rewardCost` as FormPath, {
                valueAsNumber: true,
              })}
              type="number"
              inputMode="numeric"
              placeholder="Reward cost"
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                  e.preventDefault();
                }
              }}
              className="mt-4 w-full bg-transparent placeholder:text-admin-purple text-default-white border-b-[1px] border-admin-purple focus:outline-none invalid:border-red-500 invalid:text-red-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            />
            <input
              {...register(`rewards.${index}.rewardDescription` as FormPath)}
              type="text"
              placeholder="Reward description"
              className="mt-4 w-full bg-transparent placeholder:text-admin-purple text-default-white border-b-[1px] border-admin-purple focus:outline-none invalid:border-red-500 invalid:text-red-500"
            />
            <FileInput
              register={register}
              fieldName={`rewards.${index}.rewardPicture` as FormPath}
              label="Upload reward picture"
              For="reward"
            />

            <button
              onClick={() => remove(index)}
              className="mb-4 mt-6 bg-red-700 rounded-md p-2 shadow-[0px_0px_10px_-4px_#b91c1c] text-default-white"
            >
              Delete
            </button>
          </div>
        </div>
      ))}
      <button
        onClick={(e) => {
          e.preventDefault();
          append({
            rewardTitle: "",
            rewardCost: 0,
            rewardDescription: "",
            rewardPicture: "",
          });
        }}
        className="flex items-center justify-center border-[1px] border-admin-purple rounded-md p-2 mt-4 "
      >
        <img src="/assets/add-icon.png" alt="Add Icon" className="w-5 h-auto" />
        <p className="text-default-white">Add reward</p>
      </button>
    </div>
  );
}
