import emailjs from "emailjs-com";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function Form() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = () => {
    const serviceId = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAIL_PUBLIC_KEY;

    if (!serviceId || !templateId || !publicKey) {
      return <p>There's an internal error, we'll look into it.</p>;
    } else {
      emailjs.init(publicKey);
      emailjs
        .sendForm(serviceId, templateId, "#email-form")
        .then(() => {
          if (process.env.NODE_ENV === "development") console.log("SUCCESS!");
          setIsSubmitted(true);
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development")
            console.error("FAILED...", error);
          setIsSubmitted(false);
        });
    }
  };

  return (
    <div className="flex flex-col w-full">
      <form onSubmit={handleSubmit(onSubmit)} id="email-form">
        <input
          {...register("email", { required: "Email is required." })}
          placeholder="Email"
          type="email"
          className="w-full bg-transparent placeholder:text-themed-orange placeholder:pl-2 text-themed-orange py-2 border-b-[1px] border-themed-orange focus:outline-none invalid:border-red-500 invalid:text-red-500"
        />
        {errors.email?.message && (
          <span className="text-red-500">{String(errors.email.message)}</span>
        )}
        <textarea
          {...register("message")}
          placeholder="Message"
          className="w-full bg-transparent placeholder:text-themed-orange placeholder:pl-2 text-themed-orange py-2 border-[1px] border-themed-orange focus:outline-none invalid:border-red-500 invalid:text-red-500 mt-5 h-40 resize-none"
        />
        <input
          type="submit"
          className="w-full bg-themed-orange py-2 lg:cursor-pointer mt-5"
          value="Send mail"
        />
        {isSubmitted && (
          <span className="text-green-500 mt-5">
            Your message has been sent successfully!
          </span>
        )}
      </form>
    </div>
  );
}
